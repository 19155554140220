/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { EventTestimonial_jsonld_event_read } from './EventTestimonial_jsonld_event_read';
import type { MediaObject_jsonld_event_read } from './MediaObject_jsonld_event_read';

export type Event_jsonld_event_read = {
    readonly '@context'?: (string | {
        '@vocab': string;
        hydra: Event_jsonld_event_read.hydra;
    });
    readonly '@id'?: string;
    readonly '@type'?: string;
    readonly id?: number;
    name?: string;
    description?: string;
    image?: MediaObject_jsonld_event_read;
    gallery?: Array<MediaObject_jsonld_event_read>;
    date?: string;
    type?: Event_jsonld_event_read.type;
    testimonials?: Array<EventTestimonial_jsonld_event_read>;
};

export namespace Event_jsonld_event_read {

    export enum hydra {
        HTTP_WWW_W3_ORG_NS_HYDRA_CORE_ = 'http://www.w3.org/ns/hydra/core#',
    }

    export enum type {
        BIRTHDAY_PARTY = 'BirthdayParty',
        PROPOSAL = 'Proposal',
        BACHELOR_PARTY = 'BachelorParty',
        CORPORATE_EVENT = 'CorporateEvent',
        FAMILY_EVENT = 'FamilyEvent',
        OTHER = 'Other',
    }


}

