/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type Marker_jsonld_boat_group_write = {
    readonly '@context'?: (string | {
        '@vocab': string;
        hydra: Marker_jsonld_boat_group_write.hydra;
    });
    readonly '@id'?: string;
    readonly '@type'?: string;
};

export namespace Marker_jsonld_boat_group_write {

    export enum hydra {
        HTTP_WWW_W3_ORG_NS_HYDRA_CORE_ = 'http://www.w3.org/ns/hydra/core#',
    }


}

