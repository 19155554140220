/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type BoatGroupContentDto_jsonld_boat_group_write = {
    readonly '@context'?: (string | {
        '@vocab': string;
        hydra: BoatGroupContentDto_jsonld_boat_group_write.hydra;
    });
    readonly '@id'?: string;
    readonly '@type'?: string;
    shortDescription?: string | null;
    list1?: string | null;
    list2?: string | null;
    additionalInformation?: string | null;
    embedCode?: string | null;
};

export namespace BoatGroupContentDto_jsonld_boat_group_write {

    export enum hydra {
        HTTP_WWW_W3_ORG_NS_HYDRA_CORE_ = 'http://www.w3.org/ns/hydra/core#',
    }


}

