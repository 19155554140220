import React from 'react'
import {SidebarMenuItemWithSub} from './SidebarMenuItemWithSub'
import {SidebarMenuItem} from './SidebarMenuItem'
import {useAuth} from '../../../../../app/modules/auth'
import {
  PERMISSION_BOAT_MANAGEMENT_LOCATIONS,
  PERMISSION_BOAT_MANAGEMENT_GROUPS,
  PERMISSION_BOAT_MANAGEMENT_BOATS,
  PERMISSION_BOAT_PASSES,
  PERMISSION_RESERVATION,
  PERMISSION_SUPER_ADMIN,
  PERMISSION_PROMOTIONS,
  PERMISSION_OVERRIDES,
  PERMISSION_DASHBOARD,
  PERMISSION_PAYMENTS,
  PERMISSION_EVENTS,
  PERMISSION_QR_CODES,
  PERMISSION_SETTINGS,
  PERMISSION_REPORTS,
  PERMISSION_ADDONS,
  PERMISSION_BLOCKS,
  PERMISSION_EMAILS,
  PERMISSION_TAXES,
  PERMISSION_USERS,
  PERMISSION_LOGS,
  PERMISSION_SMS,
} from '../../../../../utils/permissions'

const getMenuItemsByPermission = (permissions: string[]) => {
  if (permissions.includes(PERMISSION_SUPER_ADMIN)) {
    return (
      <>
        <SidebarMenuItem to='/dashboard' icon='/media/icons/duotune/art/art002.svg' title='Dashboard' fontIcon='bi-app-indicator' />
        <SidebarMenuItem to='/cruises' icon='/media/icons/duotune/files/fil002.svg' title='Cruises' fontIcon='bi-app-indicator' />
        <SidebarMenuItemWithSub to='/boat-management' icon='/media/icons/duotune/maps/map007.svg' title='Boat Management' fontIcon='bi-app-indicator'>
          <SidebarMenuItem to='/boat-management/boats' title='Boats' hasBullet={true} />
          <SidebarMenuItem to='/boat-management/groups' title='Groups' hasBullet={true} />
          <SidebarMenuItem to='/boat-management/locations' title='Locations' hasBullet={true} />
        </SidebarMenuItemWithSub>
        <SidebarMenuItem to='/promotions' icon='/media/icons/duotune/ecommerce/ecm003.svg' title='Promotions' fontIcon='bi-app-indicator' />
        <SidebarMenuItem to='/payments' icon='/media/icons/duotune/finance/fin002.svg' title='Payments' fontIcon='bi-app-indicator' />
        <SidebarMenuItem to='/events' icon='/media/icons/duotune/finance/fin011.svg' title='Events' fontIcon='bi-app-indicator' />
        <SidebarMenuItemWithSub to='/boat-pass' icon='/media/icons/duotune/ecommerce/ecm003.svg' title='Boat Pass' fontIcon='bi-app-indicator'>
          <SidebarMenuItem to='/boat-pass/purchased-list' title='Purchased List' hasBullet={true} />
          <SidebarMenuItem to='/boat-pass/type' title='Boat Pass Type' hasBullet={true} />
        </SidebarMenuItemWithSub>
        <SidebarMenuItem to='/addons' icon='/media/icons/duotune/general/gen041.svg' title='Add-ons' fontIcon='bi-app-indicator' />
        <SidebarMenuItem to='/overrides' icon='/media/icons/duotune/arrows/arr029.svg' title='Overrides' fontIcon='bi-app-indicator' />
        <SidebarMenuItem to='/reports' icon='/media/icons/duotune/general/gen032.svg' title='Reports' fontIcon='bi-app-indicator' />
        <SidebarMenuItem to='/taxes' icon='/media/icons/duotune/finance/fin001.svg' title='Taxes' fontIcon='bi-app-indicator' />
        <SidebarMenuItem to='/communications' icon='/media/icons/duotune/communication/com007.svg' title='Emails' fontIcon='bi-app-indicator' />
        <SidebarMenuItem to='/sms' icon='/media/icons/duotune/communication/com010.svg' title='Text Messages' fontIcon='bi-app-indicator' />
        <SidebarMenuItem to='/blocks' icon='/media/icons/duotune/abstract/abs027.svg' title='Blocks' fontIcon='bi-app-indicator' />
        <SidebarMenuItem to='/users' icon='/media/icons/duotune/communication/com014.svg' title='Users' fontIcon='bi-app-indicator' />
        <SidebarMenuItem to='/qr-codes' icon='/media/icons/duotune/technology/teh011.svg' title='QR Codes' fontIcon='bi-app-indicator' />
        <SidebarMenuItem to='/settings' icon='/media/icons/duotune/coding/cod001.svg' title='Settings' fontIcon='bi-app-indicator' />
        <SidebarMenuItem to='/logs' icon='/media/icons/duotune/coding/cod003.svg' title='Logs' fontIcon='bi-app-indicator' />
      </>
    )
  }

  return (
    <>
      {permissions.includes(PERMISSION_DASHBOARD) ? (
        <SidebarMenuItem to='/dashboard' icon='/media/icons/duotune/art/art002.svg' title='Dashboard' fontIcon='bi-app-indicator' />
      ) : null}

      {permissions.includes(PERMISSION_RESERVATION) ? (
        <SidebarMenuItem to='/cruises' icon='/media/icons/duotune/files/fil002.svg' title='Cruises' fontIcon='bi-app-indicator' />
      ) : null}

      {permissions.includes(PERMISSION_BOAT_MANAGEMENT_BOATS) ||
      permissions.includes(PERMISSION_BOAT_MANAGEMENT_GROUPS) ||
      permissions.includes(PERMISSION_BOAT_MANAGEMENT_LOCATIONS) ? (
        <SidebarMenuItemWithSub to='/boat-management' icon='/media/icons/duotune/maps/map007.svg' title='Boat Management' fontIcon='bi-app-indicator'>
          {permissions.includes(PERMISSION_BOAT_MANAGEMENT_BOATS) ? <SidebarMenuItem to='/boat-management/boats' title='Boats' hasBullet={true} /> : null}

          {permissions.includes(PERMISSION_BOAT_MANAGEMENT_GROUPS) ? <SidebarMenuItem to='/boat-management/groups' title='Groups' hasBullet={true} /> : null}

          {permissions.includes(PERMISSION_BOAT_MANAGEMENT_LOCATIONS) ? <SidebarMenuItem to='/boat-management/locations' title='Locations' hasBullet={true} /> : null}
        </SidebarMenuItemWithSub>
      ) : null}

      {permissions.includes(PERMISSION_PROMOTIONS) ? (
        <SidebarMenuItem to='/promotions' icon='/media/icons/duotune/ecommerce/ecm003.svg' title='Promotions' fontIcon='bi-app-indicator' />
      ) : null}

      {permissions.includes(PERMISSION_PAYMENTS) ? (
        <SidebarMenuItem to='/payments' icon='/media/icons/duotune/finance/fin002.svg' title='Payments' fontIcon='bi-app-indicator' />
      ) : null}

      {permissions.includes(PERMISSION_EVENTS) ? (
        <SidebarMenuItem to='/events' icon='/media/icons/duotune/ecommerce/ecm003.svg' title='Events' fontIcon='bi-app-indicator' />
      ) : null}

      {permissions.includes(PERMISSION_BOAT_PASSES) ? (
        <SidebarMenuItemWithSub to='/boat-pass' icon='/media/icons/duotune/ecommerce/ecm003.svg' title='Boat Pass' fontIcon='bi-app-indicator'>
          <SidebarMenuItem to='/boat-pass/purchased-list' title='Purchased List' hasBullet={true} />
          <SidebarMenuItem to='/boat-pass/type' title='Boat Pass Type' hasBullet={true} />
        </SidebarMenuItemWithSub>
      ) : null}

      {permissions.includes(PERMISSION_ADDONS) ? <SidebarMenuItem to='/addons' icon='/media/icons/duotune/general/gen041.svg' title='Add-ons' fontIcon='bi-app-indicator' /> : null}

      {permissions.includes(PERMISSION_OVERRIDES) ? (
        <SidebarMenuItem to='/overrides' icon='/media/icons/duotune/arrows/arr029.svg' title='Overrides' fontIcon='bi-app-indicator' />
      ) : null}

      {permissions.includes(PERMISSION_REPORTS) ? (
        <SidebarMenuItem to='/reports' icon='/media/icons/duotune/general/gen032.svg' title='Reports' fontIcon='bi-app-indicator' />
      ) : null}

      {permissions.includes(PERMISSION_TAXES) ? <SidebarMenuItem to='/taxes' icon='/media/icons/duotune/finance/fin001.svg' title='Taxes' fontIcon='bi-app-indicator' /> : null}

      {permissions.includes(PERMISSION_EMAILS) ? (
        <SidebarMenuItem to='/communications' icon='/media/icons/duotune/communication/com007.svg' title='Emails' fontIcon='bi-app-indicator' />
      ) : null}

      {permissions.includes(PERMISSION_SMS) ? (
        <SidebarMenuItem to='/sms' icon='/media/icons/duotune/communication/com010.svg' title='Text Messages' fontIcon='bi-app-indicator' />
      ) : null}

      {permissions.includes(PERMISSION_BLOCKS) ? <SidebarMenuItem to='/blocks' icon='/media/icons/duotune/abstract/abs027.svg' title='Blocks' fontIcon='bi-app-indicator' /> : null}

      {permissions.includes(PERMISSION_USERS) ? (
        <SidebarMenuItem to='/users' icon='/media/icons/duotune/communication/com014.svg' title='Users' fontIcon='bi-app-indicator' />
      ) : null}

      {permissions.includes(PERMISSION_QR_CODES) ? (
        <SidebarMenuItem to='/qr-codes' icon='/media/icons/duotune/technology/teh011.svg' title='QR Codes' fontIcon='bi-app-indicator' />
      ) : null}

      {permissions.includes(PERMISSION_SETTINGS) ? (
        <SidebarMenuItem to='/settings' icon='/media/icons/duotune/coding/cod001.svg' title='Settings' fontIcon='bi-app-indicator' />
      ) : null}

      {permissions.includes(PERMISSION_LOGS) ? <SidebarMenuItem to='/logs' icon='/media/icons/duotune/coding/cod003.svg' title='Logs' fontIcon='bi-app-indicator' /> : null}
    </>
  )
}

const SidebarMenuMain = () => {
  const {currentUser} = useAuth()

  if (!currentUser?.permissions?.length) return null

  return getMenuItemsByPermission(currentUser.permissions)
}

export {SidebarMenuMain}
