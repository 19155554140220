/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { BoatGroup_jsonld_boat_pass_type_read } from './BoatGroup_jsonld_boat_pass_type_read';
import type { MediaObject_jsonld_boat_pass_type_read } from './MediaObject_jsonld_boat_pass_type_read';
import type { TimeRestriction_jsonld_boat_pass_type_read } from './TimeRestriction_jsonld_boat_pass_type_read';

export type BoatPassType_jsonld_boat_pass_type_read = {
    readonly '@context'?: (string | {
        '@vocab': string;
        hydra: BoatPassType_jsonld_boat_pass_type_read.hydra;
    });
    readonly '@id'?: string;
    readonly '@type'?: string;
    readonly id?: number;
    name?: string;
    description?: string;
    readonly price?: number;
    cruiseNumber?: number;
    boatGroups?: Array<BoatGroup_jsonld_boat_pass_type_read>;
    timeRestriction?: TimeRestriction_jsonld_boat_pass_type_read;
    minReservationTime?: number | null;
    maxReservationTime?: number | null;
    receiptText?: string | null;
    image?: MediaObject_jsonld_boat_pass_type_read | null;
    readonly createdAt?: string;
    readonly updatedAt?: string | null;
    deletedAt?: string | null;
};

export namespace BoatPassType_jsonld_boat_pass_type_read {

    export enum hydra {
        HTTP_WWW_W3_ORG_NS_HYDRA_CORE_ = 'http://www.w3.org/ns/hydra/core#',
    }


}

