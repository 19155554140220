/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { BoatGroupContent_jsonld_available_boat_group_read } from './BoatGroupContent_jsonld_available_boat_group_read';
import type { MediaObject_jsonld_available_boat_group_read } from './MediaObject_jsonld_available_boat_group_read';
import type { PrivateType_jsonld_available_boat_group_read } from './PrivateType_jsonld_available_boat_group_read';
import type { PublicType_jsonld_available_boat_group_read } from './PublicType_jsonld_available_boat_group_read';

export type BoatGroup_jsonld_available_boat_group_read = {
    readonly '@context'?: (string | {
        '@vocab': string;
        hydra: BoatGroup_jsonld_available_boat_group_read.hydra;
    });
    readonly '@id'?: string;
    readonly '@type'?: string;
    readonly id?: number;
    name?: string;
    description?: string;
    content?: BoatGroupContent_jsonld_available_boat_group_read;
    readonly publicType?: PublicType_jsonld_available_boat_group_read | null;
    readonly privateType?: PrivateType_jsonld_available_boat_group_read | null;
    image?: MediaObject_jsonld_available_boat_group_read;
    gallery?: Array<MediaObject_jsonld_available_boat_group_read>;
    markers?: Array<string> | null;
    boatOffset?: number | null;
    isTipAvailable?: boolean;
};

export namespace BoatGroup_jsonld_available_boat_group_read {

    export enum hydra {
        HTTP_WWW_W3_ORG_NS_HYDRA_CORE_ = 'http://www.w3.org/ns/hydra/core#',
    }


}

