/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Event_event_read } from '../models/Event_event_read';
import type { Event_EventDto_event_write } from '../models/Event_EventDto_event_write';
import type { Event_EventRequestDto_event_write } from '../models/Event_EventRequestDto_event_write';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class EventService {

    /**
     * Retrieves the collection of Event resources.
     * Retrieves the collection of Event resources.
     * @returns Event_event_read Event collection
     * @throws ApiError
     */
    public static apiAdmineventsGetCollection({
        page = 1,
    }: {
        /**
         * The collection page number
         */
        page?: number,
    }): CancelablePromise<Array<Event_event_read>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/admin/events',
            query: {
                'page': page,
            },
        });
    }

    /**
     * Creates a Event resource.
     * Creates a Event resource.
     * @returns Event_event_read Event resource created
     * @throws ApiError
     */
    public static apiAdmineventsPost({
        requestBody,
    }: {
        /**
         * The new Event resource
         */
        requestBody: Event_EventDto_event_write,
    }): CancelablePromise<Event_event_read> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/admin/events',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Invalid input`,
                422: `Unprocessable entity`,
            },
        });
    }

    /**
     * Retrieves a Event resource.
     * Retrieves a Event resource.
     * @returns Event_event_read Event resource
     * @throws ApiError
     */
    public static apiAdmineventsIdGet({
        id,
    }: {
        /**
         * Event identifier
         */
        id: string,
    }): CancelablePromise<Event_event_read> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/admin/events/{id}',
            path: {
                'id': id,
            },
            errors: {
                404: `Resource not found`,
            },
        });
    }

    /**
     * Replaces the Event resource.
     * Replaces the Event resource.
     * @returns Event_event_read Event resource updated
     * @throws ApiError
     */
    public static apiAdmineventsIdPut({
        id,
        requestBody,
    }: {
        /**
         * Event identifier
         */
        id: string,
        /**
         * The updated Event resource
         */
        requestBody: Event_EventDto_event_write,
    }): CancelablePromise<Event_event_read> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/api/admin/events/{id}',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Invalid input`,
                404: `Resource not found`,
                422: `Unprocessable entity`,
            },
        });
    }

    /**
     * Removes the Event resource.
     * Removes the Event resource.
     * @returns void
     * @throws ApiError
     */
    public static apiAdmineventsIdDelete({
        id,
    }: {
        /**
         * Event identifier
         */
        id: string,
    }): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/api/admin/events/{id}',
            path: {
                'id': id,
            },
            errors: {
                404: `Resource not found`,
            },
        });
    }

    /**
     * Creates a Event resource.
     * Creates a Event resource.
     * @returns void
     * @throws ApiError
     */
    public static apiClienteventRequestsPost({
        requestBody,
    }: {
        /**
         * The new Event resource
         */
        requestBody: Event_EventRequestDto_event_write,
    }): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/client/event-requests',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Invalid input`,
                422: `Unprocessable entity`,
            },
        });
    }

    /**
     * Retrieves the collection of Event resources.
     * Retrieves the collection of Event resources.
     * @returns Event_event_read Event collection
     * @throws ApiError
     */
    public static apiClienteventsGetCollection({
        page = 1,
    }: {
        /**
         * The collection page number
         */
        page?: number,
    }): CancelablePromise<Array<Event_event_read>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/client/events',
            query: {
                'page': page,
            },
        });
    }

    /**
     * Retrieves a Event resource.
     * Retrieves a Event resource.
     * @returns Event_event_read Event resource
     * @throws ApiError
     */
    public static apiClienteventsIdGet({
        id,
    }: {
        /**
         * Event identifier
         */
        id: string,
    }): CancelablePromise<Event_event_read> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/client/events/{id}',
            path: {
                'id': id,
            },
            errors: {
                404: `Resource not found`,
            },
        });
    }

}
